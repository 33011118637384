<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Withdraw</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Withdraw</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Riwayat Withdraw</h4>
              <button @click="showFilterClikced()" type="button" class="btn btn-primary waves-effect waves-light ms-2">
                <i class="bx bx-filter-alt font-size-16 align-middle me-2" :class="{
                  'rotate-down': show_filter,
                  'un-rotate': !show_filter,
                }"></i>
                Filter
              </button>
            </div>

            <div class="card-body">
              <div class="row mb-3" v-show="show_filter">
                <div class="col-md-5 mb-2">
                  <div class="input-group">
                    <label class="mt-2 me-2">Status</label>
                    <select class="form-select" v-model="filter.status">
                      <option value="null">Pilih status</option>
                      <option value="P">Menunggu</option>
                      <option value="Y">Berhasil</option>
                      <option value="N">Gagal</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-5 mb-2">
                  <div class="input-group">
                    <label class="mt-2 me-2">Investor</label>
                    <div style="width: 80%">
                      <vue3-simple-typeahead :items="investor" :placeholder="'Masukkan nama investor...'"
                        @selectItem="selectItem" :minInputLength="0" :itemProjection="
                          (item) => {
                            return item.user.name;
                          }
                        " />
                    </div>
                  </div>
                </div>
                <div class="col-md-2 mb-2">
                  <div class="float-end">
                    <button @click="resetFilter()" type="button" class="btn btn-danger me-2">
                      <i class="bx bx-x font-size-16 align-middle"></i>
                    </button>
                    <button @click="applyFilter()" type="button" class="btn btn-primary">
                      <i class="bx bx-check font-size-16 align-middle"></i>
                    </button>
                  </div>
                </div>
              </div>
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoint } from "../../host";
import DataTable from "../../components/datatable/DataTable.vue";
import moment from "moment";
import query from "query-string";
import httpService from "../../services/http-service";
import errorService from "../../services/error-service";
import Vue3SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import permissonService from "../../services/permisson-service";
import globalService from '../../services/global-service';

export default {
  components: {
    DataTable,
    Vue3SimpleTypeahead,
  },

  created() {
    this.generateDataTableUrl();
    this.getInvestor();
    if (this.permission.can('withdraw edit')) {
      this.actions.push(
        {
          icon: "bx bx-show",
          color: "btn-info",
          tooltip: "detail",
          onclick: (data) => {
            this.$router.push({
              name: "withdraw.detail",
              params: { id: data['transactionable']['id'] },
            });
          },
        });
    }
  },

  data() {
    return {
      permission: permissonService,
      investor: [],
      filter: {
        investor_id: null,
        status: null,
      },
      show_filter: false,
      reloadDataTable: false,
      dataTableUrl: null,
      actions: [
      ],
      columnHeaders: [
        {
          text: "Tanggal",
          render: (data) => {
            return globalService.dateFormat(data.created_at);
          },
        },
        {
          text: "Investor",
          name: "user.name",
        },
        // {
        //   text: "Slot Digunakan",
        //   name: "transactionable.slot_used",
        // },
        {
          text: "Jumlah",
          render: (data) => {
            return globalService.toIdr(data["amount"]);
          },
        },
        {
          text: "Keterangan",
          name: "description",
        },
        {
          text: "Status",
          render: (data) => {
            switch (data.status) {
              case "Y":
                return '<span class="badge bg-success"">terverifikasi</span>';
              case "N":
                return '<span class="badge bg-danger"">tidak terverifikasi</span>'
              default:
                return '<span class="badge bg-warning"">Menunggu</span>';
            }
          },
        },
      ],
    };
  },

  methods: {
    async getInvestor() {
      try {
        let res = await httpService.get(httpService.generateEndpointQuery(endpoint.investor, {
          limit: 10000,
        }));
        this.investor = res.data.data;
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    generateDataTableUrl() {
      let q = new URLSearchParams();
      q.append("transactionable_type", "Withdraw");
      if (this.filter.investor_id) {
        q.append("investor_id", this.filter.investor_id);
      }
      if (this.filter.status) {
        q.append("status", this.filter.status);
      }
      this.dataTableUrl = endpoint.transaction + "?" + q.toString();
    },

    showFilterClikced() {
      this.show_filter = !this.show_filter;
    },

    selectItem(item) {
      this.filter.investor_id = item.id;
    },

    applyFilter() {
      this.generateDataTableUrl();
      this.reloadDataTable = !this.reloadDataTable;
    },

    resetFilter() {
      this.filter.investor_id = null;
      this.filter.status = null;
      this.applyFilter();
    }
  },
};
</script>

<style>
.simple-typeahead-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.simple-typeahead-input:focus-visible {
  outline: none;
}
</style>